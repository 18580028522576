import React, { FC } from 'react'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import {
  PodHistoryDonut,
  PodHistoryPeriod,
} from 'modules/UsageInsights/charts/PodHistoryDonut'

const DONUT_MAX_DIAMETER = 172

export const PodsThisWeek: FC<TileProps> = ({ ...rest }) => (
  <Tile {...rest}>
    <Tile.Heading
      color='graphica|base|100'
      content='Pods this week'
      margin={{ bottom: 'baseLoose' }}
    />
    <PodHistoryDonut
      timePeriod={PodHistoryPeriod.CalendarWeek}
      maxDiameter={DONUT_MAX_DIAMETER}
    />
  </Tile>
)
